// components/SocketConnection.js
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import io from 'socket.io-client';
import { SocketContext } from './socket-context';
import { updateLastEventTimestamps, markOffline, updateScreensStatus } from './redux/user-slice';

export default function SocketConnection({ user, registered, children }) {
    const dispatch = useDispatch();
    const lastEventTimestamps = useSelector(state => state.user?.lastEventTimestamps || {});
    const [socket, setSocket] = useState(null);

    useEffect(() => {
        let newSocket;
        if (registered) {
            newSocket = io('https://ws-test.maiasignage.com', {
                reconnectionDelayMax: 10000,
                upgrades: ['websocket'],
                auth: { token: user.token },
                query: { token: user.token },
            });

            setSocket(newSocket);

            newSocket.on('connect', () => {
                console.log('connected with:', newSocket.id);
                newSocket.emit('ping');
            });

            newSocket.on('pong', () => {
                console.log('pong received from server');
            });

            newSocket.on('disconnect', () => {
                console.log('disconnected', newSocket.id);
            });

            newSocket.onAny((eventName, ...args) => {
                console.log(args);
                if (eventName === 'device-status') {
                    const screenId = args[1]?.screen?._id;
                    const now = Date.now() + 6000;

                    if (screenId) {
                        const deviceStatusData = JSON.parse(localStorage.getItem('deviceStatusData')) || {};

                        if (!deviceStatusData[screenId]) {
                            deviceStatusData[screenId] = [];
                        }

                        const existingIndex = deviceStatusData[screenId].findIndex(item => item.timestamp === args[1].timestamp);

                        if (existingIndex !== -1) {
                            deviceStatusData[screenId][existingIndex] = args[1];
                        } else {
                            deviceStatusData[screenId].push(args[1]);
                        }

                        localStorage.setItem('deviceStatusData', JSON.stringify(deviceStatusData));

                        dispatch(updateScreensStatus({
                            id: screenId,
                            status: 'online',
                            timestamp: now
                        }));

                        dispatch(updateLastEventTimestamps({ screenId, timestamp: now }));
                    }
                }
            });

            return () => {
                if (newSocket) {
                    newSocket.disconnect();
                    console.log('Socket disconnected');
                }
            };
        }
    }, [registered, user, dispatch]);

    useEffect(() => {
        const CHECK_INTERVAL = 5 * 1000;
        const intervalId = setInterval(() => {
            const now = Date.now();
            const offlineScreenIds = [];

            for (const screenId in lastEventTimestamps) {
                const lastTimestamp = lastEventTimestamps[screenId];

                if (lastTimestamp < now) {
                    offlineScreenIds.push(screenId);
                    dispatch(updateScreensStatus({
                        id: screenId,
                        status: 'offline',
                        timestamp: now
                    }));

                    offlineScreenIds.forEach(screenId => dispatch(markOffline({ screenId })));
                }
            }
        }, CHECK_INTERVAL);

        return () => clearInterval(intervalId);
    }, [lastEventTimestamps, dispatch]);

    return (
        <SocketContext.Provider value={socket}>
            {children}
        </SocketContext.Provider>
    );
}
