import AuthenticationLayout from '@/layouts/authentication-layout'
import EmptyLayout from '@/layouts/empty-layout'
import MainLayout from '@/layouts/main-layout'

import Calendar from '@/pages/calendar'
import Dashboard from '@/pages/dashboard'
import FileManager from '@/pages/file-manager'
import Login from '@/pages/login'
import MediaLibrary from '@/pages/media-library'
import Playlists from '@/pages/playlists'
import PlaylistEdit from '@/pages/playlists/playlist-edit'
import Profile from '@/pages/profile'
import Register from '@/pages/register'
import Screens from '@/pages/screens'
import VerifyMail from '@/pages/verify-mail'

import { renderRoutes } from './generate-routes'

export const routes = [
  {
    layout: AuthenticationLayout,
    routes: [
      {
        name: 'login',
        title: 'Login page',
        component: Login,
        path: '/login',
        isPublic: true
      },
      {
        name: 'register',
        title: 'Register page',
        component: Register,
        path: '/register',
        isPublic: true
      }
    ]
  },
  {
    layout: MainLayout,
    routes: [
      {
        name: 'home',
        title: 'Home page',
        component: Dashboard,
        path: '/'
      },
      {
        name: 'screens',
        title: 'Screens page',
        component: Screens,
        path: '/screens'
      },
      {
        name: 'playlists',
        title: 'Playlists page',
        component: Playlists,
        path: '/playlists'
      },
      {
        name: 'playlist-edit',
        title: 'Playlist Edit page',
        component: PlaylistEdit,
        path: '/playlists/:id'
      },
      {
        name: 'calendar',
        title: 'calendar page',
        component: Calendar,
        path: '/schedules'
      },
      {
        name: 'media-library',
        title: 'Media library page',
        component: MediaLibrary,
        path: '/media-library'
      },
      {
        name: 'file-manager',
        title: 'File manager page',
        component: FileManager,
        path: '/file-manager/:type'
      },
      {
        name: 'profile',
        title: 'Profile page',
        component: Profile,
        path: '/profile'
      }
    ]
  },
  {
    layout: EmptyLayout,
    routes: [
      {
        name: 'verify-mail',
        title: 'Verify Mail page',
        component: VerifyMail,
        path: '/verify-mail'
      }
    ]
  }
]

export const Routes = renderRoutes(routes)
